import { React, useEffect, useState } from "react";
import logo from "../images/logo.png";
import bluelogo from "../images/blue-logo.png";
import blueFavIcon from "../images/blue-favicon.ico";
import favIcon from "../images/favicon.ico";
import { Link, NavLink } from "react-router-dom";
import Hamburger from "hamburger-react";
import SessionTimeout from "../Methods/SessionTimeout";

function Header(props) {
  const [isOpen, setOpen] = useState(false);
  const [path, setPath] = useState("");

  const { bussinesspage, myaccount, mobileOpen, shoBblueLogo } = props;
  const [loggendIn, setLoggedIn] = useState(false);
  // useEffect(() => {
  //   if (localStorage.getItem("user_details") !== null) {
  //     var user_details = JSON.parse(localStorage.getItem("user_details"));
  //     user_details = { ...user_details, address: "" };
  //     localStorage.setItem("user_details", JSON.stringify(user_details));
  //   }
  // }, []);
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (bussinesspage) {
      link.href = blueFavIcon;
    } else link.href = favIcon;
  }, []);
  useEffect(() => {
    if (localStorage.getItem("logged_in") !== null) {
      setLoggedIn(true);
    } else setLoggedIn(false);
  }, []);
  useEffect(() => {
    bussinesspage
      ? localStorage.setItem("bussiness_type", true)
      : localStorage.setItem("bussiness_type", false);
  }, []);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "js/menu.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    var url = window.location.pathname.split("/");
    setPath(url[url.length - 1]);
  }, []);

  return (
    <>
      {loggendIn == true ? <SessionTimeout /> : ""}
      <header className="header-sticky innerheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="head-bar ">
                <div className="logo">
                  <Link to={bussinesspage ? "/business" : "/"}>
                    <img
                      src={bussinesspage || shoBblueLogo ? bluelogo : logo}
                      alt="logo"
                    />
                  </Link>
                  <div className="mobile_responsive">
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                  </div>
                </div>
                {!myaccount ? (
                  bussinesspage ? (
                    <div className="header-menu">
                      <div className="menu">
                        <nav id="cssmenu" className="head_btm_menu">
                          <ul className="desktop_responsive">
                            <li className={path == "business" ? "active" : ""}>
                              <Link to="/business">Home</Link>
                            </li>
                            <li
                              className={
                                path == "business-broadband" ? "active" : ""
                              }
                            >
                              <Link to="/business-broadband">Broadband</Link>
                            </li>
                            <li
                              className={
                                path == "business-landline" ? "active" : ""
                              }
                            >
                              <Link to="/business-landline">Hosted</Link>
                            </li>

                            <li
                              className={
                                path == "business-mobile" ? "active" : ""
                              }
                            >
                              <Link to="/business-mobile">Mobile</Link>
                            </li>
                            <li
                              className={
                                path == "business-top-deals" ? "active" : ""
                              }
                            >
                              <Link to="/business-top-deals">Top Deals</Link>
                            </li>
                            <li
                              className={
                                path == "business-shop" ? "active" : ""
                              }
                            >
                              <Link to="/business-shop">Shop</Link>
                            </li>
                            <li>
                              <Link to="/">Consumer</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div className="head-btn desktop_responsive">
                        <NavLink
                          className="btn-style-login mx-2"
                          to={"/business-make-payment"}
                        >
                          <i className="fal fa-credit-card" /> Pay Bill
                        </NavLink>
                      </div>
                      <div className="head-btn desktop_responsive">
                        <Link
                          className="btn-style-login mx-2"
                          to={loggendIn ? "/my-account" : "/login"}
                        >
                          {" "}
                          <i className="fal fa-user" />{" "}
                          {loggendIn ? "My Account" : "Login"}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="header-menu">
                      <div className="menu">
                        <nav id="cssmenu" className="head_btm_menu">
                          <ul className="desktop_responsive">
                            <li
                              className={
                                path == "/" || path == "" ? "active" : ""
                              }
                            >
                              <Link to="/">Home</Link>
                            </li>
                            <li className={path == "broadband" ? "active" : ""}>
                              <Link to="/broadband">Broadband</Link>
                            </li>
                            <li className={path == "landline" ? "active" : ""}>
                              <Link to="/landline">Landline</Link>
                            </li>

                            <li className={path == "tv" ? "active" : ""}>
                              <Link to="/tv">TV</Link>
                            </li>
                            <li className={path == "mobile" ? "active" : ""}>
                              <Link to="/mobile">Mobile</Link>
                            </li>
                            <li className={path == "top-deals" ? "active" : ""}>
                              <Link to="/top-deals">Top Deals</Link>
                            </li>
                            <li className={path == "shop" ? "active" : ""}>
                              <Link to="/shop">Shop</Link>
                            </li>
                            <li className={path == "business" ? "active" : ""}>
                              <Link to="/business">Business</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div className="head-btn desktop_responsive">
                        <NavLink
                          className="btn-style-login mx-2"
                          to={"/make-payment"}
                        >
                          <i className="fal fa-credit-card" /> Pay Bill
                        </NavLink>
                      </div>
                      <div className="head-btn desktop_responsive">
                        <Link
                          className="btn-style-login mx-2"
                          to={loggendIn ? "/my-account" : "/login"}
                        >
                          {" "}
                          <i className="fal fa-user" />{" "}
                          {loggendIn ? "My Account" : "Login"}
                        </Link>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
                {!myaccount ? (
                  bussinesspage ? (
                    <div className="header-menu mobile_responsive">
                      <div className="menu">
                        <nav
                          id="cssmenu"
                          className="head_btm_menu small-screen mobile_responsive"
                        >
                          <ul className={isOpen ? "" : "d-none"}>
                            <li className={path == "business" ? "active" : ""}>
                              <Link to="/business">Home</Link>
                            </li>
                            <li
                              className={
                                path == "business-top-deals" ? "active" : ""
                              }
                            >
                              <Link to="/business-top-deals">Top Deals</Link>
                            </li>
                            <li
                              className={
                                path == "business-broadband" ? "active" : ""
                              }
                            >
                              <Link to="/business-broadband">Broadband</Link>
                            </li>
                            <li
                              className={
                                path == "business-landline" ? "active" : ""
                              }
                            >
                              <Link to="/business-landline">Hosted</Link>
                            </li>

                            <li
                              className={
                                path == "business-mobile" ? "active" : ""
                              }
                            >
                              <Link to="/business-mobile">Mobile</Link>
                            </li>

                            <li
                              className={
                                path == "business-shop" ? "active" : ""
                              }
                            >
                              <Link to="/business-shop">Shop</Link>
                            </li>
                            <li className={path == "business" ? "active" : ""}>
                              <Link to="/">Consumer</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="head-btn mobile_responsive">
                            <NavLink
                              className="btn-style-login mx-2"
                              to={"/business-make-payment"}
                            >
                              <i className="fal fa-credit-card" /> Pay Bill
                            </NavLink>
                            <Link
                              className="btn-style-login mx-2"
                              to={loggendIn ? "/my-account" : "/login"}
                            >
                              {" "}
                              <i className="fal fa-user" />{" "}
                              {loggendIn ? "My Account" : "Login"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="header-menu mobile_responsive">
                      <div className="menu">
                        <nav
                          id="cssmenu"
                          className="head_btm_menu small-screen mobile_responsive"
                        >
                          <ul className={isOpen ? "" : "d-none"}>
                            <li className={path == "/" ? "active" : ""}>
                              <Link to="/">Home</Link>
                            </li>
                            <li className={path == "top-deals" ? "active" : ""}>
                              <Link to="/top-deals">Top Deals</Link>
                            </li>
                            <li className={path == "broadband" ? "active" : ""}>
                              <Link to="/broadband">Broadband</Link>
                            </li>
                            <li className={path == "landline" ? "active" : ""}>
                              <Link to="/landline">Landline</Link>
                            </li>

                            <li className={path == "tv" ? "active" : ""}>
                              <Link to="/tv">TV</Link>
                            </li>
                            <li className={path == "mobile" ? "active" : ""}>
                              <Link to="/mobile">Mobile</Link>
                            </li>

                            <li className={path == "shop" ? "active" : ""}>
                              <Link to="/shop">Shop</Link>
                            </li>
                            <li className={path == "business" ? "active" : ""}>
                              <Link to="/business">Business</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="head-btn mobile_responsive">
                            <NavLink
                              className="btn-style-login mx-2"
                              to={"/make-payment"}
                            >
                              <i className="fal fa-credit-card" /> Pay Bill
                            </NavLink>
                            <Link
                              className="btn-style-login mx-2"
                              to={loggendIn ? "/my-account" : "/login"}
                            >
                              {" "}
                              <i className="fal fa-user" />{" "}
                              {loggendIn ? "My Account" : "Login"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
