import { React, useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";

function Content(props) {
  const {modal}=props;
  const [pageDetails, setPageDetails] = useState([]);

  useEffect(() => {
    var url = APIURL() + "term-details";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data[0]);
    });
  }, []);
  return (
    <>
      <div>
        <section className={modal ?"d-none" :"article-header"}>
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-xl-8 wow fadeInRightBig mx-auto"
                data-wow-delay="0.2s"
              >
                <div className="inner-title-small">
                  <h3>{pageDetails.title}</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy-box-sec">
          <div className="container">
            <div
              className="terms-condition"
              dangerouslySetInnerHTML={{ __html: pageDetails.description }}
            ></div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Content;
