import { React, useRef,useEffect } from "react";
import tplogo from "../../images/tp-logo.svg";
import progressimg1 from "../../images/progress-img1.png";
import progressimg2 from "../../images/progress-img2.png";
import bt from "../../images/bt.svg";
import vodafone from "../../images/vodafone.svg";

const CustomerService = (props) => {
  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  const { title, desc } = props;
  return (
    <>
      <section className="service-slider-sec ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="title">
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: desc }}></div>
              </div>
              <div className="row">                
                <div
                  ref={ref}
                  className="trustpilot-widget"
                  data-locale="en-GB"
                  data-template-id="53aa8807dec7e10d38f59f32"
                  data-businessunit-id="54736db600006400057bbc3c"
                  data-style-height="150px"
                  data-theme="light"
                >
                  <a
                    href="https://uk.trustpilot.com/review/www.poptelecom.co.uk"
                    target="_blank"
                    rel="noopener"
                  >
                    Trustpilot
                  </a>
                </div>
                <div className="col-lg-10">
                  <div className="progress mb-4">
                    <div
                      className="progress-bar progress-bar-striped barColor"
                      style={{ width: "80%" }}
                      aria-valuenow={80}
                    >
                      <img src={progressimg1} alt="image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="progress mb-4">
                    <div
                      className="progress-bar progress-bar-striped barColor2"
                      style={{ width: "60%" }}
                      aria-valuenow={60}
                    >
                      <img src={progressimg2} alt="image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="progress mb-4">
                    <div
                      className="progress-bar progress-bar-striped barColor4"
                      style={{ width: "50%" }}
                      aria-valuenow={60}
                    >
                      <img src={bt} alt="image" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="progress mb-4">
                    <div
                      className="progress-bar progress-bar-striped barColor5"
                      style={{ width: "30%" }}
                      aria-valuenow={60}
                    >
                      <img src={vodafone} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerService;
