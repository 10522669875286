import { Switch, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

function CookiesModal() {
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [pc, setPc] = useState(true);
  const [rc, setRc] = useState(true);
  const [cookies, setCookie] = useCookies(["accepted"]);

  const toggleCookiesModal = () => {
    setShow(!show);
  };
  function acceptCookies() {
    setCookie("accepted", true, { path: "/", maxAge: 2592000 });
    setShowPopup(false);
    setShow(false);
  }
  useEffect(() => {
    if (!cookies.accepted) setShowPopup(true);
  }, [cookies]);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <>
      <div id="cookiebanner" className={showPopup ? "" : "cookiebanner d-none"}>
        <div className="cookie__container">
          <div className="cookie__banner_text">
            <span className="cookie__main">
              We use cookies on our site to give you the best experience.
            </span>
            <button
              onClick={toggleCookiesModal}
              id="manage"
              className="trigger cookie__manage"
            >
              Manage your cookies
              <img
                src="https://images.ctfassets.net/aycrbiawo3x3/4gnfqKyJqufzI5d0H9XdPy/c296ac20f00cd5d65f0d1da751a07474/arrow-right-white.svg"
                alt="Arrow Right"
              />
            </button>
          </div>
          <button
            onClick={acceptCookies}
            id="accept"
            className="cookie__accept"
          >
            Accept and close
          </button>
        </div>
      </div>
      <div className={show ? "cookie__modal" : "cookie__modal hidden"}>
        <div className="cookie__modal-content">
          <div className="cookie__modal-title">
            <p className="cookie__modal-title-name">Our cookies</p>
            <span onClick={toggleCookiesModal} className="cookie__close-button">
              x
            </span>
          </div>
          <div className="cookie__modal-body">
            <div className="cookie__modal-container">
              <div>What are cookies?</div>
              <div className="cookie__modal-text">
                <p>
                  Cookies are used by websites to help them run smoothly and
                  ensure certain features work properly. At PopTelecom we use
                  cookies to remember things like items in your shopping basket
                  and preferences you’ve made on our site.
                  <a
                    href="https://new.talktalk.co.uk/legal/cookie-policy"
                    target="_blank"
                    data-di-id="di-id-3c5e97af-a00a3b52"
                  >
                    View our cookie policy.
                  </a>
                </p>
              </div>
            </div>
            <div className="save_and_reject_buttons">
              <button
                onClick={acceptCookies}
                className="save__all"
                id="accept"
                data-di-id="#accept"
              >
                Accept all
              </button>
              <button
                className="reject__all"
                id="rejectSetting"
                data-di-id="#rejectSetting"
                onClick={acceptCookies}
              >
                Reject all*
              </button>
              <p className="mt-3 mb-0">* excludes essential cookies</p>
            </div>
            <div className="cookie__modal-container">
              <div className="cookie__modal-option">Always active</div>
              <div>Essential cookies</div>
              <div className="cookie__modal-text">
                <p>
                  Essential cookies are necessary for our website to work
                  properly. Without them you wouldn’t be able to seamlessly move
                  around our website and shop with us
                </p>
              </div>
            </div>
            <div className="cookie__modal-container">
              <div className="cookie__modal-option">
                <label className="switch">
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={pc}
                    onChange={(e) => setPc(!pc)}
                  />

                  <div className="slider round">
                    <span className="on">On</span>
                    <span className="off">Off</span>
                  </div>
                </label>
              </div>
              <div>Personalisation cookies</div>
              <div className="cookie__modal-text">
                <p>
                  Personalisation cookies help us to tailor our website based on
                  your interests. They tell us how you’re using the site, so we
                  can show you products and offers you might like
                </p>
              </div>
            </div>
            <div className="cookie__modal-container">
              <div className="cookie__modal-option">
                <label className="switch">
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={rc}
                    onChange={(e) => setRc(!rc)}
                  />
                  <div className="slider round">
                    <span className="on">On</span>
                    <span className="off">Off</span>
                  </div>
                </label>
              </div>
              <div>Remarketing cookies</div>
              <div className="cookie__modal-text">
                <p>
                  Remarketing cookies record what you’ve found most interesting,
                  so we can show you relevant adverts when you’re on other
                  websites. This information is all anonymous
                </p>
              </div>
            </div>
          </div>
          <div className="cookie__modal-footer d-flex justify-content-center">
            <button
              className="cookie__accept"
              id="saveSetting"
              data-di-id="#saveSetting"
              onClick={(e) => setShow(false)}
            >
              Save cookie settings
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CookiesModal;
