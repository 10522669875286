import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Content from "../Components/ThankYou/Content";

function ThankYou({paymentMode}) {
  
  return (
    <>
      <Header />
      <div className="body-box-margin">
        <Content paymentMode={paymentMode}/>
        <Footer />
      </div>
    </>
  );
}

export default ThankYou;
