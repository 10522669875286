import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import LookBeforeChoosing from "../../../Components/Homepage/LookBeforeChoosing";
import BannerSection from "../../../Components/OfferPage/BannerSection";
import Deals from "../../../Components/OfferPage/Deals";
import TrustPilot from "../../../Components/TrustPilot";
import { APIURL } from "../../../Methods/Fetch";
import { axiosGet } from "../../../Methods/Save";

function OfferPage(props) {
  const { business } = props;
  const [pageDetails, setPageDetails] = useState([]);

  useEffect(() => {
    var url = APIURL() + "offer-page-details";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data[0]);
    });
  }, []);
  console.log("pageDetails", pageDetails);
  return (
    <>
      <div className={business ? "buisness-mobile" : ""}>
        <div className={business ? "buisness" : ""}>
          <Header bussinesspage={business ? true : false} />
          <div className="body-box-margin">
            <BannerSection pageDetails={pageDetails} />
            <LookBeforeChoosing
              business={business ? true : false}
              offerPage={true}
            />
            <Deals pageDetails={pageDetails} />
            <Footer bussinesspage={business ? true : false} />
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferPage;
